import React from "react";
import SEO from "../../../common/SEO";
import HeaderOne from "../../../common/header/HeaderOne";
import SectionTitle from "../../../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../../../elements/timeline/TimelineTwo";
import Separator from "../../../elements/separator/Separator";
import FooterOne from "../../../common/footer/FooterOne";
import Copyright from "../../../common/footer/Copyright";
import AdvanceTabOne from "../../../elements/advancetab/AdvanceTabOne";
import TabTwo from "../../../elements/tab/TabTwo";
import TabThree from "../../../elements/tab/TabThree";

const dataList1 = [
  {
    image: "/images/service/serviice-01.jpg",
    title: "Intelligent Virtual Tutor",
    description:
      " An AI-powered virtual tutor that can answer students' questions, provide personalized feedback, and offer insights into their learning progress. With conversational AI, the virtual tutor can engage in natural language interactions, providing a more human-like experience that can help students learn more effectively",
  },
  {
    image: "/images/service/serviice-02.jpg",
    title: "Personalized Learning Assistant",
    description:
      "An AI-powered learning assistant that can help students set learning goals, recommend personalized learning resources, and provide feedback on their progress. By integrating with a student's learning history and other data sources, the learning assistant can offer tailored recommendations and insights that can improve learning outcomes.",
  },
  {
    image: "/images/service/serviice-03.jpg",
    title: "Interactive Learning Modules",
    description:
      " AI-powered interactive learning modules that engage students in learning through natural language interactions. These modules can be designed to cover a variety of topics and can be customized to meet the needs of different learners. The conversational AI can adapt to the student's pace, style, and preferences, providing feedback and guidance in real-time. This approach can help to improve engagement and learning outcomes, while also providing a more enjoyable and personalized learning experience.",
  },
];

const dataList2 = [
  {
    image: "/images/service/serviice-04.jpg",
    title: "Virtual Health Assistan",
    description:
      "An AI-powered virtual assistant that can help patients manage their health and wellness by providing personalized recommendations, answering health-related questions, and connecting patients with appropriate care providers. The conversational AI can engage in natural language interactions to provide more human-like interactions and help patients feel more at ease.",
  },
  {
    image: "/images/service/serviice-01.jpg",
    title: "Medical Diagnosis and Triage",
    description:
      "An AI-powered medical diagnosis and triage system that can help patients and healthcare providers to identify potential health issues and determine appropriate treatment options. With conversational AI, the system can engage in natural language interactions to provide more personalized and effective communication, improving the accuracy of diagnoses and reducing the risk of misdiagnosis.",
  },
  {
    image: "/images/service/serviice-06.jpg",
    title: "Mental Health Chatbot",
    description:
      "An AI-powered mental health chatbot that can provide patients with support, guidance, and resources for managing mental health issues. With conversational AI, the chatbot can engage in natural language interactions to provide more personalized and effective communication, improving the overall patient experience and reducing the stigma associated with seeking mental health care",
  },
];

const dataList3 = [
  {
    image: "/images/service/serviice-03.jpg",
    title: "Personalized Financial Planning",
    description:
      " An AI-powered financial planning assistant that can help customers manage their finances, set financial goals, and develop personalized financial plans. With conversational AI, the assistant can engage in natural language interactions to provide more personalized and effective communication, improving the overall customer experience and enabling customers to make informed financial decisions.",
  },
  {
    image: "/images/service/serviice-05.jpg",
    title: "Fraud Detection and Prevention",
    description:
      " An AI-powered fraud detection and prevention system that can help banks and financial institutions to detect and prevent fraudulent transactions. With conversational AI, the system can engage in natural language interactions to provide more personalized and effective communication, improving the accuracy of fraud detection and reducing the risk of false positives.",
  },
  {
    image: "/images/service/serviice-01.jpg",
    title: "Customer Support Chatbot",
    description:
      "An AI-powered chatbot that can help customers with their banking and financial needs, including balance inquiries, transaction history, and account management. With conversational AI, the chatbot can engage in natural language interactions to provide more personalized and effective communication, improving the overall customer experience and reducing the need for customer support representatives.",
  },
];
const dataList4 = [
  {
    image: "/images/service/serviice-06.jpg",
    title: "Citizen Services",
    description:
      "An AI-powered citizen services assistant that can help citizens access public services, information, and resources. With conversational AI, the assistant can engage in natural language interactions to provide more personalized and effective communication, improving the overall citizen experience and enabling citizens to find the information they need quickly and easily.",
  },
  {
    image: "/images/service/serviice-04.jpg",
    title: "Emergency Response",
    description:
      "An AI-powered emergency response system that can help first responders and emergency services to respond to emergencies more quickly and efficiently. With conversational AI, the system can engage in natural language interactions to provide more personalized and effective communication, improving the overall emergency response and reducing the time it takes to respond to emergency situations.",
  },
  {
    image: "/images/service/serviice-05.jpg",
    title: "Public Safety",
    description:
      "An AI-powered public safety system that can help law enforcement and other public safety officials to monitor and respond to potential security threats. With conversational AI, the system can engage in natural language interactions to provide more personalized and effective communication, improving the overall public safety and reducing the risk of security incidents.",
  },
];

const ConversationalAI = () => {
  return (
    <>
      <SEO title="Conversational AI" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12a.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">Conversational AI</span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    Taking Your Customer Service to the Next Level
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">
                    The Future of Customer Experience is here: Conversational AI
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  Our conversational AI service creates personalized customer
                  experiences while improving operational efficiencies with
                  minimal investment. Our intelligent virtual agents engage in
                  human-like interactions and can be deployed across multiple
                  channels. Our solutions integrate with your CRMs, CMS,
                  databases, and knowledge bases to provide accurate responses.
                  We also offer tools for conversation flow design, intent
                  identification, and performance monitoring.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start Service Area  */}

        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we can do for you"
                  title="Conversational AI Solutions Across Industries"
                  description="Our solutions integrate with your CRMs, CMS, databases, and knowledge bases to provide accurate responses."
                />
              </div>
            </div>
            <TabThree
              dataList1={dataList1}
              dataList2={dataList2}
              dataList3={dataList3}
              dataList4={dataList4}
            />
          </div>
        </div>
        {/* End Service Area  */}

        <div className="rwt-timeline-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div>

        <Separator />

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default ConversationalAI;
