import React from "react";
import SEO from "../../../common/SEO";
import HeaderOne from "../../../common/header/HeaderOne";
import SectionTitle from "../../../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../../../elements/timeline/TimelineTwo";
import Separator from "../../../elements/separator/Separator";
import FooterOne from "../../../common/footer/FooterOne";
import Copyright from "../../../common/footer/Copyright";
import TabThree from "../../../elements/tab/TabThree";

const dataList1 = [
  {
    image: "/images/service/serviice-01.jpg",
    title: "Document Translation",
    description:
      "An AI-powered translation system that can help educators to translate course materials and documents into multiple languages. With Document AI, the system can automatically extract and translate text from course materials and documents, enabling educators to reach a wider audience and provide materials in multiple languages.",
  },
  {
    image: "/images/service/serviice-02.jpg",
    title: "Textbook Analysis",
    description:
      "Analysis: An AI-powered textbook analysis system that can help educators to analyze and evaluate the effectiveness of textbooks and course materials. With Document AI, the system can automatically extract important information from textbooks, enabling educators to more easily evaluate the quality and relevance of materials.",
  },
  {
    image: "/images/service/serviice-03.jpg",
    title: "Automated Grading",
    description:
      "An AI-powered grading system that can help educators to grade student assignments more efficiently and accurately. With Document AI, the system can automatically extract and evaluate important information from student assignments, providing faster feedback and reducing the time and effort required for manual grading.",
  },
];

const dataList2 = [
  {
    image: "/images/service/serviice-04.jpg",
    title: "Medical Transcription",
    description:
      "An AI-powered medical transcription system that can help healthcare providers to transcribe patient medical records more efficiently. With Document AI, the system can automatically transcribe patient data from audio recordings, reducing the time and effort required for manual transcription.",
  },
  {
    image: "/images/service/serviice-01.jpg",
    title: "Claims Processing",
    description:
      "An AI-powered claims processing system that can help healthcare providers to process medical claims more efficiently. With Document AI, the system can automatically extract and classify data from medical documents, enabling faster and more accurate claims processing.",
  },
  {
    image: "/images/service/serviice-06.jpg",
    title: "Electronic Medical Record (EMR) Analysis",
    description:
      "An AI-powered EMR analysis system that can help healthcare providers to analyze patient data more efficiently. With Document AI, the system can automatically extract and evaluate data from EMRs, enabling faster and more accurate analysis of patient health information.",
  },
];

const dataList3 = [
  {
    image: "/images/service/serviice-03.jpg",
    title: "Loan Processing",
    description:
      "An AI-powered loan processing system that can help financial institutions to process loan applications more efficiently. With Document AI, the system can automatically extract and evaluate data from loan documents, enabling faster and more accurate loan decisions.",
  },
  {
    image: "/images/service/serviice-05.jpg",
    title: "Mortgage Processing",
    description:
      "An AI-powered mortgage processing system that can help financial institutions to process mortgage applications more efficiently. With Document AI, the system can automatically extract and evaluate data from mortgage documents, enabling faster and more accurate mortgage decisions.",
  },
  {
    image: "/images/service/serviice-01.jpg",
    title: "KYC (Know Your Customer) Compliance",
    description:
      "An AI-powered KYC compliance system that can help financial institutions to comply with KYC regulations more efficiently. With Document AI, the system can automatically extract and evaluate data from customer documents, enabling faster and more accurate KYC compliance decisions.",
  },
];
const dataList4 = [
  {
    image: "/images/service/serviice-06.jpg",
    title: "Records Management",
    description:
      "An AI-powered records management system that can help government agencies to manage and analyze public records more efficiently. With Document AI, the system can automatically extract and evaluate data from public records, enabling faster and more accurate analysis of public data.",
  },
  {
    image: "/images/service/serviice-04.jpg",
    title: "Contract Management",
    description:
      "An AI-powered contract management system that can help government agencies to manage and analyze contracts more efficiently. With Document AI, the system can automatically extract and evaluate data from contracts, enabling faster and more accurate contract analysis.",
  },
  {
    image: "/images/service/serviice-05.jpg",
    title: "Legal Document Analysis",
    description:
      "An AI-powered legal document analysis system that can help government agencies to analyze legal documents more efficiently. With Document AI, the system can automatically extract and evaluate data from legal documents, enabling faster and more accurate legal analysis.",
  },
];

const DocumentAI = () => {
  return (
    <>
      <SEO title="Document AI" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12b.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">Document AI</span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    Streamlining Document Processing and Analysis
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">
                    Maximizing Efficiency with Document AI: Better Data, Better
                    Results
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  Document AI automates data extraction from unstructured
                  documents, improving data accuracy and streamlining workflows
                  in healthcare, public sector, banking, and education. It can
                  improve care coordination, compliance, fraud detection,
                  portfolio management, and administrative tasks. With Document
                  AI, businesses can unlock their data's potential and optimize
                  their operations.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start Service Area  */}

        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we can do for you"
                  title="Document AI Solutions Across Industries"
                  description="Streamline workflows, improve accuracy, and optimize your operations. Explore our industry-specific use cases below and contact us to learn more!"
                />
              </div>
            </div>
            <TabThree
              dataList1={dataList1}
              dataList2={dataList2}
              dataList3={dataList3}
              dataList4={dataList4}
            />
          </div>
        </div>
        {/* End Service Area  */}

        <div className="rwt-timeline-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div>

        <Separator />

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default DocumentAI;
