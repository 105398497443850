import React from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import ServiceOne from "../elements/service/ServiceOne";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
const HomeDefault = () => {
  return (
    <>
      <SEO title="Cloud Transformation Consulting" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 variation-default height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-3.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <h1 className="title display-one">
                    Generative AI consulting.
                  </h1>
                  <p className="description">
                    Empowering organizations to reach new heights with
                    Foundational models in the cloud.
                  </p>
                  <div className="button-group">
                    <Link
                      className="btn-default btn-medium round btn-icon"
                      to="/about-us"
                    >
                      About Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="/contact"
                    >
                      Contact Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gapBottom pt--100">
          <div className="container">
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default HomeDefault;
