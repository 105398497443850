import React from "react";
import SEO from "../../../common/SEO";
import HeaderOne from "../../../common/header/HeaderOne";
import SectionTitle from "../../../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../../../elements/timeline/TimelineTwo";
import Separator from "../../../elements/separator/Separator";
import FooterOne from "../../../common/footer/FooterOne";
import Copyright from "../../../common/footer/Copyright";
import AdvanceTabOne from "../../../elements/advancetab/AdvanceTabOne";
import TabTwo from "../../../elements/tab/TabTwo";
import TabThree from "../../../elements/tab/TabThree";

const dataList1 = [
  {
    image: "/images/service/serviice-01.jpg",
    title: "Student performance data",
    description:
      "Implement a Data Mesh solution to more effectively manage and analyze student performance data, empowering educators with data-driven insights to support student success",
  },
  {
    image: "/images/service/serviice-02.jpg",
    title: "Research",
    description:
      "Help research departments and faculty to develop domain-oriented data products, driving research innovation and increased collaboration between research groups.",
  },
  {
    image: "/images/service/serviice-03.jpg",
    title: "Student engagement ",
    description:
      " Implement a Data Mesh solution to more effectively manage and analyze student engagement data, allowing educators to better understand and improve student engagement.",
  },
];

const dataList2 = [
  {
    image: "/images/service/serviice-04.jpg",
    title: "Patient data management",
    description:
      "Design a Data Mesh architecture to streamline and optimize patient data management, enabling healthcare providers to deliver more personalized and patient-centered care",
  },
  {
    image: "/images/service/serviice-01.jpg",
    title: "Integration for healthtech platforms",
    description:
      " Integrate Data Mesh with healthtech platforms to improve data quality, facilitate secure data sharing, and enhance the overall patient experience.",
  },
  {
    image: "/images/service/serviice-06.jpg",
    title: "Medical imaging data",
    description:
      "Implement a Data Mesh solution to more effectively manage and analyze medical imaging data, allowing radiologists and other clinicians to make more accurate and timely diagnoses",
  },
];

const dataList3 = [
  {
    image: "/images/service/serviice-03.jpg",
    title: "Customer analytics",
    description:
      "Help banks and financial institutions to develop domain-oriented data products, driving customer-centric innovation and enabling personalized financial services.",
  },
  {
    image: "/images/service/serviice-05.jpg",
    title: "Transaction data",
    description:
      "Implement a Data Mesh solution to more effectively manage and analyze transaction data, enabling banks and financial institutions to identify trends, patterns, and anomalies that inform strategic business decisions.",
  },
  {
    image: "/images/service/serviice-01.jpg",
    title: "Fintech platforms",
    description:
      "Integrate Data Mesh with fintech platforms to improve data quality, facilitate secure data sharing, and enhance the overall customer experience.",
  },
];
const dataList4 = [
  {
    image: "/images/service/serviice-06.jpg",
    title: "Citizen Data",
    description:
      "Implement a Data Mesh solution to more effectively manage and analyze citizen data, enabling government agencies to provide more personalized and targeted services to citizens.",
  },
  {
    image: "/images/service/serviice-04.jpg",
    title: "Data sharing",
    description:
      "Design and implement a Data Mesh architecture tailored to the unique data management needs of government agencies, including improved data sharing, interoperability, and security.",
  },
  {
    image: "/images/service/serviice-05.jpg",
    title: "Emergency response",
    description:
      "Implement a Data Mesh solution to more effectively manage and analyze emergency response data, enabling faster and more efficient responses to emergency situations.",
  },
];

const DataMesh = () => {
  return (
    <>
      <SEO title="Data Mesh" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12j.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">Data Mesh</span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    Empowering Your Business with Data Ownership and Control
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">
                    Transform Your Data Strategy with Data Mesh: Harnessing the
                    Power of Decentralization
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  Our Data Mesh consulting services empower healthcare, public
                  sector, banking, and education organizations to take advantage
                  of a decentralized, autonomous approach to data ownership. By
                  leveraging Data Mesh, clients can improve data quality,
                  streamline data processing, and make more informed decisions.
                  Our industry-specific solutions are tailored to each business
                  domain, allowing domain experts to manage their own data
                  pipelines and gain a deeper understanding of their data. With
                  our expertise and experience, we help organizations meet their
                  data goals and stay ahead in an ever-changing data landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start Service Area  */}

        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we can do for you"
                  title="Data Mesh Solutions Across Industries"
                  description="Transform your data infrastructure with our industry-specific Data Mesh expertise."
                />
              </div>
            </div>
            <TabThree
              dataList1={dataList1}
              dataList2={dataList2}
              dataList3={dataList3}
              dataList4={dataList4}
            />
          </div>
        </div>
        {/* End Service Area  */}

        <div className="rwt-timeline-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div>

        <Separator />

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default DataMesh;
