import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li>
        <Link to="/conversational-ai">Conversational AI</Link>
      </li>
      <li>
        <Link to="/document-ai">Document AI</Link>
      </li>
      <li>
        <Link to="/medical-ai">Medical AI</Link>
      </li>
      <li>
        <Link to="/data-mesh">Data Mesh</Link>
      </li>
    </ul>
  );
};
export default Nav;
