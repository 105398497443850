import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// Pages import Here
import HomeDefault from "./pages/HomeDefault";

// Elements import Here
import Contact from "./elements/contact/Contact";
import Error from "./pages/Error";

//AI pages
import ConversationalAI from "./pages/solutions/ai/ConversationalAI";
import DocumentAI from "./pages/solutions/ai/DocumentAI";
import MedicalAI from "./pages/solutions/ai/MedicalAI";
import DataMesh from "./pages/solutions/data/DataMesh";

// Import Css Here
import "./assets/scss/style.scss";

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={HomeDefault}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/conversational-ai"}`}
            exact
            component={ConversationalAI}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/document-ai"}`}
            exact
            component={DocumentAI}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/medical-ai"}`}
            exact
            component={MedicalAI}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/data-mesh"}`}
            exact
            component={DataMesh}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            exact
            component={Contact}
          />
          <Redirect
            to={`${process.env.PUBLIC_URL + "/error"}`}
            exact
            component={Error}
          />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
