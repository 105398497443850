import React from "react";
import SEO from "../../../common/SEO";
import HeaderOne from "../../../common/header/HeaderOne";
import SectionTitle from "../../../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../../../elements/timeline/TimelineTwo";
import Separator from "../../../elements/separator/Separator";
import FooterOne from "../../../common/footer/FooterOne";
import Copyright from "../../../common/footer/Copyright";
import TabThree from "../../../elements/tab/TabThree";

const dataList2 = [
  {
    image: "/images/service/serviice-04.jpg",
    title: "Medical Imaging Analysis",
    description:
      "An AI-powered medical imaging analysis system that can help healthcare providers to analyze medical images more efficiently. With Medical AI, the system can automatically extract and analyze data from medical images, enabling faster and more accurate diagnosis.",
  },
  {
    image: "/images/service/serviice-01.jpg",
    title: "Patient Monitoring",
    description:
      "An AI-powered patient monitoring system that can help healthcare providers to monitor patients more efficiently. With Medical AI, the system can analyze patient data in real-time, providing alerts and predictions for potential health issues.",
  },
  {
    image: "/images/service/serviice-06.jpg",
    title: "Personalized Medicine",
    description:
      "An AI-powered personalized medicine system that can help healthcare providers to tailor treatment plans to individual patients. With Medical AI, the system can analyze patient data to identify the most effective treatment options, improving patient outcomes and reducing healthcare costs.",
  },
];

const dataList4 = [
  {
    image: "/images/service/serviice-06.jpg",
    title: "Disease Outbreak Prediction",
    description:
      "An AI-powered disease outbreak prediction system that can help public health agencies to predict and prepare for potential disease outbreaks. With Medical AI, the system can analyze data from multiple sources, such as social media and news reports, to identify potential disease outbreaks before they occur.",
  },
  {
    image: "/images/service/serviice-04.jpg",
    title: "Public Health Research",
    description:
      "An AI-powered public health research system that can help public health agencies to conduct research more efficiently. With Medical AI, the system can analyze data from multiple sources, such as health surveys and research papers, to identify new research opportunities and provide recommendations for research topics.",
  },
  {
    image: "/images/service/serviice-05.jpg",
    title: "Health Monitoring",
    description:
      "An AI-powered health monitoring system that can help public health agencies to monitor population health more efficiently. With Medical AI, the system can analyze health data from multiple sources, such as wearable devices and health surveys, to identify potential health issues.",
  },
];
const MedicalAI = () => {
  return (
    <>
      <SEO title="Medical AI" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12c.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">Medical AI</span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    Transforming Patient Care with Artificial Intelligence
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">
                    Advancing Medical Diagnosis and Treatment with AI Technology
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  Medical AI is revolutionizing healthcare by providing accurate
                  and efficient diagnosis, treatment, and research capabilities.
                  With the ability to process large amounts of medical data
                  quickly, Medical AI can improve patient outcomes, reduce
                  healthcare costs, and enhance clinical workflows. From medical
                  imaging to patient monitoring to drug discovery, Medical AI
                  has applications across a range of industries, including
                  healthcare, the public sector, and academical medical centers.
                  By leveraging the power of Medical AI, organizations can
                  unlock new opportunities to improve patient care and drive
                  innovation in the healthcare industry.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start Service Area  */}

        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we can do for you"
                  title="Medical AI Solutions for Healthcare and Public Health"
                  description="Discover the potential of Medical AI - explore industry-specific use cases below."
                />
              </div>
            </div>
            <TabThree dataList2={dataList2} dataList4={dataList4} />
          </div>
        </div>
        {/* End Service Area  */}

        <div className="rwt-timeline-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div>

        <Separator />

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default MedicalAI;
