import React from "react";
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiMail, FiMapPin } from "react-icons/fi";
import { BsFillChatDotsFill } from "react-icons/bs";
import SectionTitle from "../sectionTitle/SectionTitle";
const ContactOne = () => {
  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <BsFillChatDotsFill />
                  </div>
                  <div className="inner">
                    <h4 className="title">Live Chat</h4>
                    <p>
                      <a href="#">Soon</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Email Address</h4>
                    <p>
                      <a href="mailto:contact@canivel.com">
                        contact@canivel.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">FAQ</h4>
                    <p>
                      <a href="#">Find the answers you need. Click here.</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-4">
          <SectionTitle
            textAlign="text-left"
            radiusRounded=""
            subtitle="Contact Form"
            title="Gen AI, <br />Data <br />& Cloud ? <br />Contact us!"
            description=""
          />
        </div>
        <div className="col-lg-8 mt_md--30 mt_sm--30">
          <ContactForm formStyle="contact-form-1" />
        </div>
      </div>
    </>
  );
};
export default ContactOne;
