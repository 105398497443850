import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

const TabThree = (props) => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12">
                <div className="rn-default-tab style-three">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      {props.dataList1 ? (
                        <Tab>
                          <div className="rn-tab-button">
                            <button>Education</button>
                          </div>
                        </Tab>
                      ) : (
                        ""
                      )}
                      {props.dataList2 ? (
                        <Tab>
                          <div className="rn-tab-button">
                            <button>Healthcare & Life Sciences</button>
                          </div>
                        </Tab>
                      ) : (
                        ""
                      )}
                      {props.dataList3 ? (
                        <Tab>
                          <div className="rn-tab-button">
                            <button>Banking & Financial Services</button>
                          </div>
                        </Tab>
                      ) : (
                        ""
                      )}
                      {props.dataList4 ? (
                        <Tab>
                          <div className="rn-tab-button">
                            <button>Public Sector</button>
                          </div>
                        </Tab>
                      ) : (
                        ""
                      )}
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    {props.dataList1 ? (
                      <TabPanel>
                        <div className="rn-tab-content">
                          <div className="inner">
                            <div className="row align-items-center row--30">
                              <div className="col-lg-12">
                                <div className="row row--15 mt_dec--30 service-wrapper">
                                  {props.dataList1.map((val, i) => (
                                    <div
                                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                                      key={i}
                                    >
                                      <div className="card-box card-style-1 text-start">
                                        <div className="inner">
                                          <div className="image">
                                            <Link to="#service">
                                              <img
                                                src={`${val.image}`}
                                                alt="card Images"
                                              />
                                            </Link>
                                          </div>
                                          <div className="content">
                                            <h4 className="title mb--20">
                                              <Link
                                                to="#service"
                                                dangerouslySetInnerHTML={{
                                                  __html: val.title,
                                                }}
                                              ></Link>
                                            </h4>
                                            <p
                                              className="description b1 color-gray mb--0"
                                              dangerouslySetInnerHTML={{
                                                __html: val.description,
                                              }}
                                            ></p>
                                            <Link
                                              className="btn-default btn-small"
                                              to="#service"
                                            >
                                              Read More
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    ) : (
                      ""
                    )}
                    {props.dataList2 ? (
                      <TabPanel>
                        <div className="rn-tab-content">
                          <div className="inner">
                            <div className="row align-items-center row--30">
                              <div className="col-lg-12">
                                <div className="row row--15 mt_dec--30 service-wrapper">
                                  {props.dataList2.map((val, i) => (
                                    <div
                                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                                      key={i}
                                    >
                                      <div className="card-box card-style-1 text-start">
                                        <div className="inner">
                                          <div className="image">
                                            <Link to="#service">
                                              <img
                                                src={`${val.image}`}
                                                alt="card Images"
                                              />
                                            </Link>
                                          </div>
                                          <div className="content">
                                            <h4 className="title mb--20">
                                              <Link
                                                to="#service"
                                                dangerouslySetInnerHTML={{
                                                  __html: val.title,
                                                }}
                                              ></Link>
                                            </h4>
                                            <p
                                              className="description b1 color-gray mb--0"
                                              dangerouslySetInnerHTML={{
                                                __html: val.description,
                                              }}
                                            ></p>
                                            <Link
                                              className="btn-default btn-small"
                                              to="#service"
                                            >
                                              Read More
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    ) : (
                      ""
                    )}
                    {props.dataList3 ? (
                      <TabPanel>
                        <div className="rn-tab-content">
                          <div className="inner">
                            <div className="row align-items-center row--30">
                              <div className="col-lg-12">
                                <div className="row row--15 mt_dec--30 service-wrapper">
                                  {props.dataList3.map((val, i) => (
                                    <div
                                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                                      key={i}
                                    >
                                      <div className="card-box card-style-1 text-start">
                                        <div className="inner">
                                          <div className="image">
                                            <Link to="#service">
                                              <img
                                                src={`${val.image}`}
                                                alt="card Images"
                                              />
                                            </Link>
                                          </div>
                                          <div className="content">
                                            <h4 className="title mb--20">
                                              <Link
                                                to="#service"
                                                dangerouslySetInnerHTML={{
                                                  __html: val.title,
                                                }}
                                              ></Link>
                                            </h4>
                                            <p
                                              className="description b1 color-gray mb--0"
                                              dangerouslySetInnerHTML={{
                                                __html: val.description,
                                              }}
                                            ></p>
                                            <Link
                                              className="btn-default btn-small"
                                              to="#service"
                                            >
                                              Read More
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    ) : (
                      ""
                    )}
                    {props.dataList4 ? (
                      <TabPanel>
                        <div className="rn-tab-content">
                          <div className="inner">
                            <div className="row align-items-center row--30">
                              <div className="col-lg-12">
                                <div className="row row--15 mt_dec--30 service-wrapper">
                                  {props.dataList4.map((val, i) => (
                                    <div
                                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                                      key={i}
                                    >
                                      <div className="card-box card-style-1 text-start">
                                        <div className="inner">
                                          <div className="image">
                                            <Link to="#service">
                                              <img
                                                src={`${val.image}`}
                                                alt="card Images"
                                              />
                                            </Link>
                                          </div>
                                          <div className="content">
                                            <h4 className="title mb--20">
                                              <Link
                                                to="#service"
                                                dangerouslySetInnerHTML={{
                                                  __html: val.title,
                                                }}
                                              ></Link>
                                            </h4>
                                            <p
                                              className="description b1 color-gray mb--0"
                                              dangerouslySetInnerHTML={{
                                                __html: val.description,
                                              }}
                                            ></p>
                                            <Link
                                              className="btn-default btn-small"
                                              to="#service"
                                            >
                                              Read More
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabThree;
